.link-portal {
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 80px;
    }
    .link-portal__links {
        margin-top: 60px;
        }
        .link-portal__card {
            text-decoration: none;
            color: #fff;
            display: block;
            &:hover {
                color: white;
                background-color: rgba(34, 28, 70, .8);
                transition: all 250ms ease-out;
                }
        }
        .link-portal__card p {
            font-size: 0.83rem;
            }
@media (width < 768px) {
    .link-portal__card + .link-portal__card {
        margin-top: 60px;
        }
}


@media (width >= 768px) {

    .link-portal {
        padding-top: 350px;
        }
    .link-portal--lg {
        padding-top: 100px;
        padding-bottom: 40px;
        }
    .link-portal__links {
        display: flex;
        justify-content: space-between;
        margin-left: -30px;
        margin-right: -30px;
        margin-top: 100px;
        }
    .link-portal__links--lg {
        flex-wrap: wrap;
        margin-bottom: -80px;
        }
        .link-portal__card {
            width: 33.333%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            }
        .link-portal__card .cta--read-more {
            margin-top: auto;
            }
        .link-portal__links--lg .link-portal__card {
            width: 45%;
            padding: 30px;
            margin-bottom: 80px;
            }

}
