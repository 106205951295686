/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size-normalized)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

@media (width < 768px) {

    html {
        font-size: --base-font-size;
        }

}

::selection {
    background-color: rgba(181,0,86,.99);
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    text-decoration: underline;
    text-decoration-skip: ink;
    color: var(--brand-pink);
    }
a:hover {
    color: var(--brand-pink-rollover);
}

/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    @mixin base-margin;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    letter-spacing: .015em;
    }

h1 {
    font-size: 2.25rem;
    line-height: 1.15;
    }

h2 {
    font-size: 1.5rem;
    line-height: 1.15;
    }

h3 {
    font-size: 1.25rem;
    line-height: 1.15;
    }

h4 {
    font-size: 1rem;
    line-height: 1.15;
    }

h5 {
    font-size: 0.9rem;
    line-height: 1.15;
    }

h6 {
    font-size: 0.75rem;
    line-height: 1.15;
    }

.title {
    color: #fff;
    font-size: 1.66rem;
    line-height: 1;
    font-weight: 400;
    margin: 0 0 0.5em -2px;
    }
    @media (width < 768px) {
    .container--flush .title {
        padding:  0 20px;
        }
    }
.section__title {
    margin-top: 0;
    padding: 0 20px;
    margin-bottom: 2rem;
    @media (width >=1200px) {
        margin-bottom: 4rem;
        }
    }

.title--sub {
    font-size: 1.11rem;
    }

.txt--center {
    text-align: center;
    }

.txt--uppercase {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    }

.txt--primary-colour {
    color: #B50056;
    }

.txt--sm {
    font-size: 0.8rem;
    }

.lead {
    font-size: 2rem;
    line-height: 3rem;
    }


/** ------------------------------
    Content
    ------------------------------ */
p,
ul,
ol,
dl {
    @mixin base-margin;
    }

@media (width >= 768px) {

   .title {
        font-size: 1.95rem;
        }
    .title--sub {
        font-size: 1.4rem;
        }
    .section__title {
        padding: 0;
        }
}
@media (width >= 1024px) {

   .title {
        font-size: 2.15rem;
        }
    .title--sub {
        font-size: 1.5rem;
        }
    .section__title {
        padding: 0;
        }
}


