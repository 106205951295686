/** ------------------------------
    Embedded HubSpot forms
    ------------------------------ */
.hbspt-form {
    --field-colour: #fff;
    --field-border-color: var(--field-colour);
    --field-background-color: transparent;

    @media (width >= 1024px) {
        --field-border-color: #b50056;
    }

    font-size: 15px;

    & fieldset {
        max-width: none !important;
        }

    /* General field */
    & .hs-form-field {
        margin-bottom: 20px;

        & label {
            display: block;
            }

        & .hs-input {
            width: 100%;
            border-radius: 0;
            background-color: transparent;
            color: var(--field-colour);

            &:focus {
                outline: 0;
                }
            }

        }

    /* Text field */
    & .hs-fieldtype-text {

        & .hs-input {
            padding-bottom: 5px;
            border: 0;
            border-bottom: 1px solid var(--field-border-color);
            }

        }

    /* Textarea field */
    & .hs-fieldtype-textarea {

        & .hs-input {
            padding: 5px;
            min-height: 5em;
            border: 1px solid var(--field-border-color);

                @media (width >= 1024px) {
                    min-height: 13em;
                }
            }

        }

    /* Submit button */
    & .hs-submit {

        & [type="submit"] {
            @mixin button-reset;
            cursor: pointer;
            display: block;
            margin: auto;
            padding: 0 15px;
            line-height: 32px;
            border: 1px solid var(--field-border-color);
            color: var(--field-colour);
            @mixin text-uppercase;
                &:hover {
                    color: var(--field-border-color);
                }
            }

        }

    /* Error messages */
    & .hs-error-msgs {
        @mixin list-reset;
        margin-top: 5px;
        font-size: 90%;
        }
    & .hs_error_rollup {
        display: none;
        }
    }
