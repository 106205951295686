.card-grid--team {
    display: flex;
    align-items: initial;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;
    }
    .card--team {
        display: flex;
        width: calc(50% - 20px);
        padding: 20px;
        text-align: center;
        margin: 15px 10px;

        & a {
            color: #fff;
            text-decoration: none;
            }
        }
        .card--team__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            font-size: 0.55rem;
            }
    .card--team + .card--team {
        margin-top: 15px;
        }
        .card--team img {
            border-radius: 50%;
            }
        .card--team__title {
            margin-top: 20px;
            margin-bottom: 5px;
            font-size: 0.83rem;
            text-transform: uppercase;
            }
        .card--team p {
            margin-bottom: 10px;
            }
        .team-member__social {
            margin-top: auto;
            text-decoration: none;
            }
            .team-member__social a {
                color: transparent;
                }
            .team-member__social a svg {
                transition: fill .25s ease-out;
            }
            .team-member__social a:hover svg {
                fill: var(--brand-pink);
                }
                .team-member__social a + a {
                    margin-left: 20px;
                    }

@media (width >= 768px) {

    .card--team {
        width: calc(33% - 20px);
        padding: 30px;
        margin: 30px 10px;
        font-size: 0.65rem;
        }
    .card--team + .card--team {
        margin-top: 30px;
        }
        .card--team p {
            margin-bottom: 20px;
            }
        .card--team__title {
            font-size: 1rem;
            }
        .team-member__social a + a {
            margin-left: 10px;
            }
}
@media (width >= 1024px) {
    .card--team {
        width: calc(25% - 20px);
        }
}

