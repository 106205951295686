/** ------------------------------
    Action sheet
    ------------------------------ */
.action-sheet {
    }
    .action-sheet__btn {
        @mixin button-reset;
        display: block;
        margin: auto;
        padding: 30px 60px;
        max-width: 720px;
        background-color: #6f437f;
        @mixin box-shadow;
        color: #fff;
        font-size: 30px;
        @mixin text-uppercase;
        cursor: pointer;
        }
    .action-sheet__overlay {
        display: none;
        @mixin content-overlay;
        }
    .action-sheet__overlay--active {
        display: flex;
        }
        .action-sheet__body {
            margin: auto;
            padding: 2.5%;
            width: 95%;
            max-width: 720px;
            max-height: 100%;
            background-color: #6f437f;
            overflow-y: auto;
            }
