.masthead {
	padding: 35px 0 0;
	}
	.masthead__brand-link {
		display: block;
		width: 100px;
		margin: 0 auto 0;
		position: relative;
		z-index: 100;
		}

.brand-mark {
	display: block;
	width: 100px;
	}

.flush-content-to-top {

	& .masthead {
		position: absolute;
		width: 100%;
		margin-bottom: 0;
	}

}

.masthead {
	height: 170px;
	}

.flush-content-to-top {

	& .masthead {
		margin-bottom: -170px;
		position: static;
	}

}


.masthead .container {
	position: relative;
	}
.masthead__popups,
.masthead__links {
	display: block;
	position: absolute;
	right: 20px;
	top: 125px;
	z-index: 1000;
	}
.masthead__links {
	display: flex;
	}
.masthead__popups {
	left: 10px;
	top: 185px;
    right: 10px;
	}
.masthead__links > a {
	text-decoration: none;
	display: flex;
	width: 35px;
	height: 35px;
	align-items: center;
	justify-content: center;
	}
.masthead__links > a .fill {
	transition: fill .25s ease-out;
}
.masthead__links > a:hover .fill {
	fill: var(--brand-pink);
}
.masthead__links > a + a {
	margin-left: 20px;
	}
	.masthead__popups__search {
		width: 100%;
		background: #221C46;
		padding: 20px;
		display: none;
		}
	.masthead__popups__share {
		display: none;
		position: absolute;
		right: 0;
		background: #221C46;
		padding: 20px;
		}
	.masthead__links__close {
		display: none;
		}

	.share-visible .masthead__links__item__share svg,
	.search-visible .masthead__links__item__search svg {
		display: none;
		}
	.masthead__links__item svg {
		width: 20px;
		height: auto;
		}
	.masthead__links__item svg.masthead__links__close {
		width: 15px;
		margin: 0 2px;
		}
	.share-visible .masthead__popups__share,
	.search-visible .masthead__popups__search,
	.share-visible .site-cover,
	.search-visible .site-cover {
		display: inline-block;
		}
	.search-visible .masthead__links__item__search .masthead__links__close,
	.share-visible .masthead__links__item__share .masthead__links__close {
		display: inline-block;
		}
	.share-visible .masthead__links__item__share,
	.search-visible .masthead__links__item__search {
		background: #221c46;
		}


	.masthead__popups__share:after,
	.masthead__popups__search:after {
		content: "";
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 20px solid #221C46;
		position: absolute;
		right: 62px;
		top: -19px;
		}
	.masthead__popups__share:after {
		right: 8px;
		}
	.masthead__popups__share p {
		margin-bottom: 7px;
		text-transform: uppercase;
		font-size: 0.8rem;
		letter-spacing: 0.1em;
		}
		.masthead__popups__share svg {
			width: 17px;
			height: auto;
			}
		.masthead.copied .masthead__popups__share__item--link svg,
		.masthead__popups__share a:hover svg {
			fill: #b50056;
			}
		.masthead__popups__share a span {
			display: none;
			}
		.masthead.copied .masthead__popups__share__item--link span {
			display: block;
			position: absolute;
			bottom: -16px;
			width: 70px;
			left: 50%;
			margin-left: -35px;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-size: 0.5rem;
			text-align: center;
			}
	.masthead__popups__share__item {
		position: relative;
		text-decoration: none;
		}
		.masthead__popups__share__item + .masthead__popups__share__item {
			margin-left: 20px;
			}
		.masthead__popups__search input {
			width: 100%;
			background: transparent;
			padding: 5px;
			color: #fff;
			font-size: 1.5rem;
			font-family: inherit;
			border: none;
			border-bottom: 2px solid #6F437F;
			margin-top: 5px;
			margin-bottom: 3px;
			}
		.masthead__popups__search input::placeholder {
			color: #6F437F;
			}
		.masthead__popups__search input:focus {
			outline: none;
			}
.masthead__links>.masthead__links__item__print {
	display: none;
	}
	
	.site-cover {
		position: fixed;
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background: rgba(0,0,0,0.5);
		}

@media (height < 610px) {
	.home .masthead__links,
	.page-template-default .masthead__links {
		top: 0;
	}
	.home .masthead__popups,
	.page-template-default .masthead__popups {
		top: 60px;
	}
	.hero-carousel__about p {
	    margin: 10px auto 0;
	}
}

@media (width >= 768px) {

	.masthead {
		padding: 70px 0 0;
		}

		.masthead__brand-link {
			width: 120px;
			}


	.masthead__popups,
	.masthead__links {
		top: 160px;
		}
	.masthead__popups {
		top: 220px;
		/*right: 0;*/
		/*left: -10px;*/
	}

	.brand-mark {
		width: 120px;
		}
	
	.single-post .masthead__links>.masthead__links__item__print {
		display: flex;
		}

	.single-post .masthead__popups__search:after {
			right: 117px;
		}

}

@media (width >= 768px) {
	.masthead {
		height: 240px;
	}

	.flush-content-to-top {

		& .masthead {
			margin-bottom: -240px;
			position: static;
		}
	}

	.masthead__popups__share {
		right: 0px;
		}

}





