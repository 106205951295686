/** ------------------------------
    Carousel card
    ------------------------------ */
.carousel-card {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    }
    .carousel-card__img {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(85, 80, 115, 0) 0, #111236 100%);
            opacity: 0.7;
            }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            }
        }

    .carousel-card__body {
        margin: 0 auto;
        max-width: 1110px;
        width: 100%;
        }

@media (width >= 768px) {

    .carousel-card__body {
        margin-bottom: 95px;
        margin-bottom: calc(((100vh - 720px)/2) + 230px);
        transform: translateY(100%);
        position: relative;
        top: -44px;
        }
        .related-content-card {
            width: 50%;
            }

}

@media (width >= 768px) and (height < 720px) {

    .carousel-card__body {
        margin-bottom: 230px;
        transform: translateY(100%);
        position: relative;
        top: -44px;
        }

}

@media (width < 768px) {

    .carousel-card__body {
        & .related-content-card {
            max-width: none;
            }
        }

}

@media (width >= 768px) {

    .hero-carousel {
        & .carousel-card {
            height: 100vh !important;
            min-height: 625px;
            }
        }

}
