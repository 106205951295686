/** ------------------------------
    Base
    ------------------------------ */
.btn {
    display: inline-block;
    padding: 0 var(--btn-padding);
    height: var(--btn-height);
    line-height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    vertical-align: middle;
    }
.btn:focus {
    outline: 0;
    }
    .btn__icon {
        display: inline-block;
        margin: 0 4px;
        vertical-align: middle;
        }

.cta--read-more {
    text-transform: uppercase;
    font-size: 0.72rem;
    color: #B50056;
    text-decoration: none;
    letter-spacing: 0.1em;
    font-weight: bold;
    }
    .cta--read-more svg {
        margin-left: 10px;
        position: relative;
        top: -1px;
        left: 0;
        transition: left 150ms ease-out;
        }
    .cta--read-more:hover svg {
        left: 10px;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--block {
    width: 100%;
    }

.btn--ghost {
    border: 1px solid currentcolor;
    background-color: transparent;
    color: inherit;
    }
