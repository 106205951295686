.tags {
    max-width: 920px;
    margin: 60px auto 0;
    }
.tags__title {
    display: block;
    color: var(--brand-pink);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 0.72rem;
    margin-top: 0;
    margin-bottom: 20px;
    }
    .tags__links {
        margin: 5px -5px;
        }
        .tags__links a {
            display: inline-block;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            padding: 5px;
            font-size: 0.72rem;
            border: 1px solid #B50056;
            margin: 7px 5px;
            }


@media (width >= 768px) {

    .tags {
        margin-top: 80px;
        }
    .content-header .tags {
        margin-top: 70px;
        }

    .tags__links a {
        padding: 5px 10px;
        transition: all 250ms ease-out;
        position: relative;
        top: 0;
        box-shadow: none;
        }
        .tags__links a:hover {
            background: #B50056;
            color: #fff;
            top: -2px;
            box-shadow: -5px 5px 15px 0 rgba(0,0,0,.5);
            }
}

@media (width >= 1100px) {

    .content-header .tags {
        width: calc(100% - 760px);
        }

}
