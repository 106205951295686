/** ------------------------------
    Quote
    ------------------------------ */
.quote {
    margin: auto;
    max-width: 960px;
    text-align: center;
    }
    .quote__footer {
        text-align: right;
        }

/** ------------------------------
    Size modifiers
    ------------------------------ */
.quote--large {
    & > p {
        @mixin font-scale 43;
        }

    & .quote__footer {
        @mixin font-scale 30;
        }
    }

.quote--small {
    & > p {
        @mixin font-scale 30;
        }

    & .quote__footer {
        @mixin font-scale 20;
        }
    }
