.awards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    }
    .awards__item {
        padding: 20px 25px;
        max-width: 140px;
        display: flex;
        align-items: center;
        }

@media (width >= 768px) {
    .awards {
        margin-top: 60px;
        }
        .awards__item {
            max-width: 160px;
            }
}
