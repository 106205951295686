.card--services__content__copy {
    color: white;
    }

@media (width < 768px) {

    .card--services__content {
        text-align: center;
        }
    .card--services__content__holder {
        padding-top: 78%;
        background-size: 100% auto;
        background-repeat: no-repeat
        }
    .card--services + .card--services {
        margin-top: 80px;
        }
}

@media (width >= 768px) {
    .card-grid--services {
        margin-left: 0;
        margin-right: 0;
        }
    .card-grid--services--list {
        /* max-width: 825px; */
        margin: 0 auto;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        }
    .card-grid--services--list .card {
        padding: 0;
        }
    .card-grid--services--list .card:hover {
        /* background: transparent;
        box-shadow: none; */
        }
    .card-grid--services--list .card + .card {
        margin-top: 70px;
        }
    .card--services {
        width: 100%;
        }
    .card-grid--services--list .card--services {
        width: 100%;
        }
        .card--services__content__holder {
            position: relative;
            padding-bottom: 50%;
            background-size: 50% auto;
            background-position: 20px 20px;
            background-repeat: no-repeat;
            }
        .card-grid--services--list .card--services__content__holder {
            padding-bottom: 0;
            padding-top: 120px;
            background-size: 220px auto;
            background-position: 0px 0px;
            }
            .card--services__content__copy {
                position: absolute;
                bottom: 5%;
                width: 100%;
                padding: 0 5% 0 20%;
                }
            .card-grid--services--list .card--services__content__copy {
                position: static;
                padding: 0 0 0 90px;
                }
}

@media (width >= 992px) {
    .card--services {
        width: 50%;
    }
    .card--services__content__holder {
        padding-bottom: 100%;
        background-size: 75% auto;
    }
        
}

@media (width >= 1200px) {
    .card-grid--services--list {
        display: flex;
        flex-wrap: wrap;
        }
        .card-grid--services--list .card--services {
            width: 50%;
            flex-shrink: 0;
            }
            .card-grid--services--list .card  {
                margin-top: 70px;
                padding-right: 35px;
                }
            .card-grid--services--list .card:nth-child(1),
            .card-grid--services--list .card:nth-child(2) {
                margin-top: 0;
                }
            .card-grid--services--list .card--services__content__holder {
                background-position: 10px 10px;
                }
}

@media (width < 1200px) {
    .card-grid--services--list .card:hover {
        background: transparent;
        box-shadow: none;
        }
}


