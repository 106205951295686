.content-page img {
    margin-top: 3rem;
    margin-bottom: 3rem;
    }
    .content-page .title {
        margin-bottom: 1.25em;
        }

.full-bleed {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    & > img {
        width: 100%;
        }
    }

.content-footnote {
    padding: 10px;
    }
    .content-footnote h4 {
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: #fff;
        font-size: 0.72rem;
        margin-bottom: 0.5em;
        }
    .content-footnote p {
        font-size: 0.66rem;
        font-style: italic;
        }

.content-authors {
    margin-top: 40px;
    }

    .content-author + .content-author {
        margin-top: 35px;
        }

        .content-author {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
        }

        .content-author__img {
            display: block;
            width: 90px;
            margin-bottom: 20px;
            margin-right: 30px;

            & img {
                border-radius: 50%;
                }
            }
        .content-author__details {
            & a {
                color: #fff;
                text-decoration: none;
                }
                &:hover {
                    color: var(--brand-pink);
                }
                & svg {
                    transition: fill .25s ease-out;
                    &:hover {
                        fill: var(--brand-pink);
                    }
                }
            }
            .content-author__name {
                margin-bottom: 5px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                }
            .content-author__title {
                font-size: 0.55rem;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                margin-bottom: 10px;
                }

 .content-header .title,
 .content-header .title--sub {
    margin-bottom: 1.1em;
    }

@media (width >= 768px) {

    .content-page__img-split {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        }
        .content-page__img-split > div {
            width: calc(50% - 10px);
            }

    .content-authors {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        }
        .content-author {
            /* width: 48%; */
            display: flex;
            margin-right: 40px;
            }
        .content-author + .content-author {
            margin-top: 0;
            }
            .content-author__img {
                width: 70px;
                margin-right: 15px;
                }
            .content-author__name {
                font-size: 0.65rem;
                }
            .content-author__title {
                font-size: 0.45rem;
                margin-bottom: 10px;
                }

    }

@media (width >= 1100px) {
    .content-header {
        overflow: hidden;
        }
        .content-header > div {
            float: left;
            }
        /* .content-authors {
            padding-left: 90px;
            display: block;
            margin-top: 15px;
            }
            .content-author + .content-author {
                margin-top: 35px;
                }
            .content-author {
                width: 100%;
                } */

}

@media (width >= 1320px) {
    .content-relative {
        position: relative;
        }
        .content-footnote {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            width: 250px;
            padding: 0;
            margin-left: 40px;
            }
}
