.section--contact-block {
    padding: 90px 0;
    }
.contact-block {
    background: #221C46;
    padding: 25px 25px;
    max-width: 790px;
    margin: 0 auto;
    box-shadow: -10px 10px 30px 0px rgba(0,0,0,0.5);
    }
    .contact-block p:last-of-type {
        margin-bottom: 0;
        }


@media (width >= 768px) {

    .section--contact-block {
        padding: 190px 0;
        }
        .contact-block {
            padding: 40px 25px;
            }
        .contact-block > p {
            font-size: 1.5rem;
            }
}
