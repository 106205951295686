.footer {
    background: #181239;
    padding-top: 50px;

    & .brand-mark {
        margin: auto;
        }
    }
    .footer__mb {
        text-align: center;
        padding: 20px 0;
        margin-top: 50px;
        }

    .footer__mb p {
        padding: 0 20px;
        }
    .footer__mb__social-links {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        }
        .footer__mb__social-links a {
            display: block;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #B50056;
            border-radius: 50%;
            margin: 0 10px;
            }

        .footer__mb__jobs {
            margin-top: 70px;
            }
            .footer__mb__jobs__cta {
                display: inline-block;
                padding: 5px 10px;
                color: #fff;
                border: 1px solid #B50056;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                text-decoration: none;
                font-size: 0.72rem;
                }

            .footer__mb__links {
                list-style: none;
                margin: 70px 0 20px;
                padding: 0;
                color: #fff;
                text-decoration: none;
                font-size: 0.72rem;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                }
                .footer__mb__links a {
                    display: block;
                    color: #fff;
                    text-decoration: none;
                    font-size: 0.72rem;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    margin: 10px 0;
                    }

@media (width < 768px) {
    .footer__dt {
        display: none;
        }
}

@media (width >= 768px) {
    .footer {
        padding-top: 75px;
        }
        .footer__mb {
            display: none;
            }

    .footer__dt {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        padding-bottom: 80px;
        flex-wrap: wrap;
        }
        .footer__dt > div {
            width: 50%;
            }
        .footer__dt > .footer__dt__locations {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            }
            .footer__dt__locations > div {
                width: 50%;
                }

    .footer__dt__sectors ul {
        list-style: none;
        margin: 0;
        padding: 0;
        }
        .footer__dt__sectors ul li + li {
            margin-top: 20px;
            }
        .footer__dt__sectors ul a {
            text-decoration: none;
            color: #B50056;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-size: 1.222rem;
            &:hover {
                color: var(--brand-pink-rollover);
                }
            }
    
    .footer__dt__jobs {
        padding-top: 70px;
    }
    .footer__dt__jobs__cta {
        font-size: 0.65rem;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        padding: 5px 10px;
        border: 1px solid #B50056;
        letter-spacing: 0.1em;
        transition: all 250ms ease-out;
        position: relative;
        top: 0;
        box-shadow: none;

        }
    .footer__dt__jobs__cta:hover {
        background: #B50056;
        color: #fff;
        top: -2px;
        box-shadow: -5px 5px 15px 0 rgba(0,0,0,.5);
        }

    .footer__dt__jobs-links {
        display: flex;
        /*flex-direction: column;*/
        }
        .legals__dt__links {
            margin: auto 0 0;
            list-style: none;
            padding: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 0.65rem;
            letter-spacing: 0.1em;
            font-weight: 300;
            }
            .legals__dt__links li + li {
                margin-top: 5px;
                }
            .legals__dt__links a {
                color: currentcolor;
                text-decoration: none;

                &:hover {
                    color: var(--brand-pink);
                    }
                }
        .legals__dt__links__largescreen {
            width: 100%;
            padding-top: 50px;
            text-align: center;
            clear: both;
            }
            .legals__dt__links__largescreen li {
                display: inline-block;
                margin-left: 22px;
                margin-right: 22px;
            }

    .footer__dt__location {
        /*font-size: 0.9rem;*/
        margin-top: 60px;
        }
        .footer__dt__location p {
            margin-bottom: 10px;
            }
        .footer__dt__location__title {
            display: block;
            text-transform: uppercase;
            color: #B50056;
            margin-bottom: 10px;
            font-size: 1.222rem;
            letter-spacing: 0.1em;
            }
        .footer__dt__location__email {
            color: #B50056;
            }
        .footer__dt__location__email:hover {
            color: var(--brand-pink-rollover);
        }

        .footer__dt__location__social-links {
            display: flex;
            list-style: none;
            margin: 10px 0 0 0;
            padding: 0;
            }
            .footer__dt__location__social-links li + li {
                margin-left: 15px;
                }
            .footer__dt__location__social-links a svg {
                transition: fill 250ms ease-out;
                }
            .footer__dt__location__social-links a:hover svg {
                fill: #B50056;
                }
}

@media (width >= 992px) {
    .footer__dt__sectors ul a {
        font-size: 1.111rem;
        }
    .footer__dt__location {
        font-size: 0.75rem;
        }
        .footer__dt__location p {
            padding-right: 40px;
            }
            
    .footer__dt > div {
        width: 25%;
        }
    .footer__dt__jobs {
        max-width: 200px;
    }
    .footer__dt__jobs p {
        font-size: 1rem;
    }
    .footer__dt>.footer__dt__locations {
        width: 75%;
        margin-top: -60px;
        display: block;
        }
        .footer__dt__location__title {
            font-size: 1.111rem;
            }
        .footer__dt__locations > div {
            width: 33.33%;
            display: block;
            float: left;
            }
            .footer__dt__locations > div:nth-child(3n+1) {
                clear: both;
                }


    .legals__dt__links {
        padding-top: 70px;
        font-size: 13px;
    }
}
@media (width >= 1200px) {
    .footer__dt__location {
        font-size: 0.8889rem;
        }
    .legals__dt__links {
        padding-top: 90px;
    }
}