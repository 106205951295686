/** ------------------------------
    Related content card
    ------------------------------ */
.related-content-card {
    display: block;
    padding: 20px 15px 50px 75px;
    text-decoration: none; /* This component is usually an anchor */
    background-color: rgba(34, 28, 70, .8);
    color: #fff;
    max-width: 555px;
        &:hover {
            color: #fff;
            }
    }
    .related-content-card__meta {
        @mixin list-reset;
        display: flex;
        margin-bottom: 8px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: .55rem;

        & > :not(:first-child) {
            margin-left: 10px;
            }
        }
        .related-content-card__meta-type {
            color: var(--brand-pink);
            }
    .related-content-card__title {
        margin-bottom: 0;
        font-size: 1rem;
        text-align: left;
        color: #fff;
        }
    .related-content-card__cta {
        display: block;
        text-transform: uppercase;
        font-size: .65rem;
        margin-top: 20px;
        text-align: left;
        letter-spacing: 0.1em;
        color: var(--brand-pink);
        
        & svg {
            margin-left: 10px;
            position: relative;
            top: -1px;
            transition: left 150ms ease-out;
            left: 0;
            }
        }

    .related-content-card__cta:hover svg {
        left: 10px;
        }


@media (width < 768px) {

    .related-content-card__cta,
    .related-content-card__excerpt {
        display: none;
        }

}

@media (width >= 768px) {

    .related-content-card {
        display: block;
        padding: 25px;
        }
        .related-content-card__meta {
            font-size: .65rem;
            letter-spacing: 1px;
            }
        .related-content-card__title {
            margin-bottom: 10px;
            font-size: 1.5rem;
            }

}

/** ------------------------------
    Modifiers
    ------------------------------ */
@media (width >= 768px) {

    .related-content-card--align-left {
        margin-right: auto;
        }

    .related-content-card--align-center {
        margin: auto;
        }

    .related-content-card--align-right {
        margin-left: auto;
        }

}
