/** ------------------------------
    Breadcrumbs
    ------------------------------ */
@custom-media --collapsed-breadcrumbs (width < 640px);
@custom-media --expanded-breadcrumbs (width >= 640px);

.breadcrumbs {
    margin-bottom: 50px;
    }
    .breadcrumbs__list {
        @mixin list-reset;
        }
        .breadcrumbs__list-item {
            font-size: .65rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            letter-spacing: 0.1em;

            & a {
                color: #fff;
                text-decoration: none;
                }

            }

@media (--collapsed-breadcrumbs) {

    .breadcrumbs__list-item {

        &:not(:last-child) {
            display: none;
            }

        &:last-child {
            &::before {
                content: "";
                position: relative;
                top: 0;
                width: 6px;
                height: 6px;
                border-top: 3px solid var(--brand-pink);
                border-left: 3px solid var(--brand-pink);
                transform: rotate(-45deg);
                display: inline-block;
                margin-right: 3px;
                border-radius: 1px;
                }
            }

        }

}

@media (--expanded-breadcrumbs) {

    .breadcrumbs__list {
        display: flex;
        }
        .breadcrumbs__list-item {

            & + .breadcrumbs__list-item::before {
                content: "&bull;";
                margin-left: 20px;
                margin-right: 20px;
                }

            & a:hover {
                color: var(--brand-pink);
                }

            }

}
