.navigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100vw - 40px);
    background: #221C46;
    transform: translateX(-100%);
    transition: transform 250ms ease-out;
    z-index: 9999;
    }
.navigation-active .navigation {
    transform: translateX(0);
    }
    .navigation__toggle {
        display: block;
        position: absolute;
        bottom: 48px;
        right: -63px;
        width: 88px;
        height: 88px;
        transform: translateX(0px) scale(0.74);
        transition: transform 250ms ease-out, background-color 250ms ease-out;
        background: #B50056;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        padding-right: 10px;
        overflow: hidden;
        }
        .navigation__toggle:hover {
            background: var(--brand-pink-rollover);
        }
        .navigation__toggle span {
            transition: all 600ms ease-out;

            &:matches(:first-child, :last-child) {
                display: block;
                width: 54px;
                height: 2px;
                background: #fff;
                border-radius: 2px;
                transition: all 600ms ease-out;
                opacity: 0;
                transform: translateY(-40px);
                }
            &:matches(:last-child) {
                transform: translateY(40px);
                }
            }

            .navigation-active .navigation__toggle span:nth-child(2) {
                opacity: 0;
                }
            .navigation-active .navigation__toggle span:first-child {
                opacity: 1;
                transform: rotate(-45deg) translateY(9px) translateX(-8px);
                }
            .navigation-active .navigation__toggle span:last-child {
                opacity: 1;
                transform: rotate(45deg) translateY(-10px) translateX(-9px);
                }
    .navigation-active .navigation .navigation__toggle {
        padding-right: 17px;
        transform: translateX(-20px) scale(0.74);
        }

.navigation__holder {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    }
    .navigation__container {
        position: absolute;
        width: 200%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        transform: translateX(0);
        transition: transform 200ms ease-out;
        }
    .navigation__container.show-contact {
        transform: translateX(-50%);
        }
    .navigation__container > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: calc(100% - 63px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        }
     div.navigation__contact-toggle-holder {
        top: auto;
        bottom: 0;
        height: 63px;
        width: 100%;
        }
    .navigation__container > .navigation__contact {
        left: 50%;
        }

.navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    }

.navigation__contact-toggle a,
.navigation ul > li > a {
    display: flex;
    width: 100%;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 20px;
    }

    .navigation__contact-toggle-holder .navigation__contact-toggle:last-child a:before,
    .navigation__contact-toggle-holder .navigation__contact-toggle:first-child a:after,
    .navigation__container > div > ul > li > a:after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #B50056;
        border-left: 2px solid #B50056;
        transform: rotate(-45deg);
        margin-left: 15px;
        position: relative;
        top: -3px;
        }
    .navigation__container > div > ul > li > a.active {
        color: #B50056;
        }

    .navigation__container > div > ul > li > a.active:after {
         transform: rotate(-225deg);
         top: 2px;
        }
    .navigation__contact-toggle-holder .navigation__contact-toggle:last-child a:before{
        transform: rotate(45deg);
        margin-left: 0;
        margin-right: 15px;
        top: -1px;
        }
    .navigation__content > ul > li > a.active + ul {
        display: block;
        }

    .navigation__contact-toggle-holder .navigation__contact-toggle:first-child a:after {
        transform: rotate(-135deg);
        top: -1px;
        }
    .navigation__content > ul > li > ul a {
        padding: 10px 30px;
        font-size: 0.8333rem;
        }

    .navigation__container > div > ul ul {
        display: none;
        }

    @media (width < 1024px) {
        .navigation__contact__map,
        .navigation__container > div > ul ul > div {
            display: none;
            }
    }

    .navigation__contact > ul > li ul {
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        }
    .navigation__contact > ul > li ul li {
        margin-right: 20px;
    }
    .navigation__contact > ul > li > a.active + ul {
        display: flex;
        margin-bottom: 20px;
        }
    @media (width < 1024px) {
        .navigation__contact > ul > li ul a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            border: 2px solid #B50056;
            border-radius: 50%;
            }
        }

.navigation__contact-form,
.navigation__crisis {
    padding: 30px 20px;
    background: #6F437F;
    letter-spacing: 1px;
    flex-grow: 1;
    box-shadow: inset 0 23px 20px -11px rgba(0,0,0,.5);
    margin-bottom: 0;
    }
.navigation__content > ul > li:last-child > a.active + ul {
    margin-bottom: 20px;
    }
    .navigation__contact-form .title,
    .navigation__crisis .title {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 1.25em;
        }
    .navigation__crisis p,
    .navigation__crisis a {
        font-size: 0.8333rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 0.8em;
        color: #fff;
        }
    .navigation__crisis a {
        text-transform: initial;
        text-decoration: none;
        }

.navigation__contact-form {
    width: 100%;
    }
.navigation__contact-form p {
    font-size: 0.72rem;
    }
.navigation__contact-form input,
.navigation__contact-form textarea,
.navigation__contact-form label {
    display: block;
    width: 100% !important;
    background: transparent;
    border: none;
    border: 1px solid #B50056;
    outline: none;
    box-shadow: none;
    color: #fff;
    font-size: 0.72rem;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
    }
.navigation__contact-form input.hs-button {
    width: auto !important;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 7px 15px;
    }
.navigation__contact-form label {
    border: 0;
    margin-bottom: 0;
    padding: 0 0 4px 0;
    }
.navigation__contact-form fieldset {
    max-width: none !important;
    }
.navigation__contact-form .hs-form-field {
    width: 100% !important;
    }
.navigation__contact-form textarea {
    padding: 10px;
    border: 1px solid #fff;
    height: 100px;
    }
    .navigation__contact-form .hs-error-msg {
        color: red;
        margin-top: -16px;
        }
.navigation__contact-form *::-webkit-input-placeholder {
    color: #fff;
    }
.navigation__contact-form *::-moz-placeholder {
    color: #fff;
    }
.navigation__contact-form *:-ms-input-placeholder {
    color: #fff;
    }
.navigation__contact-form *:-moz-placeholder {
    color: #fff;
    }

.navigation__contact-form button {
    border: 1px solid #fff;
    border-radius: 0;
    background: transparent;
    outline: none;
    box-shadow: none;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid #fff;
    padding: 10px;
    font-size: 0.72rem;
    letter-spacing: 0.1em;
    }

.navigation__contact-toggle {
    margin-top: auto;
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 50%;
    background: #221C46;
    }
    .navigation__contact-toggle:first-child {
        left: 0;
        }
.navigation__content .navigation__contact-toggle {
    left: 0;
    }





@media (width >= 1024px) and (height > 660px) {

    .navigation {
        width: calc(100vw - 60px);
        max-width: 1350px;
        max-height: 720px;
        top: 50%;
        transform: translateY(-50%) translateX(-100%);
        transition: transform 500ms ease-out;
        background: transparent;
        }
    .navigation-active .navigation {
        transform: translateY(-50%) translateX(0);
        }
        .navigation__holder {
            background: #221C46;
            opacity: 0;
            position: relative;
            transition: opacity 500ms ease-out 0ms;
            box-shadow: -10px 10px 30px 0 rgba(0,0,0,.5);
            pointer-events: none;
            }
        .navigation-active .navigation__holder {
            opacity: 1;
            pointer-events: auto;
            /* transition: opacity 350ms ease-out 0ms,
                        left 3350ms ease-out 0ms; */
            }
        .navigation__toggle {
            transform: translateX(0) scale(1);
            bottom: 230px;
            right: -70px;
            }
        .navigation-active .navigation .navigation__toggle {
            transform: translateX(-26px) scale(1);
            }

    .navigation__container > div {
        padding: 55px;
        display: block;
        overflow: hidden;
        height: 100%;
        }
    .navigation__container > .navigation__contact-toggle-holder {
        height: 1px;
        overflow: visible;
        padding: 0;
        }

    .navigation__container > div > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        }
        .navigation__container > div > ul > li {
            flex: 1;
            }
    .navigation__container > div > ul ul {
        display: block;
        margin-top: 10px;
        }
    .navigation__container > .navigation__contact > ul ul {
        padding: 0;
        font-size: 15px;
        }

    .navigation__container > .navigation__contact > ul > li > ul {
        display: none;
        }
    .navigation__container > div > ul > li > a {
        padding: 0;
        color: #B50056;
        font-size: 1.2rem;
        }
        .navigation__container > div > ul > li > a:hover {
            color: var(--brand-pink-rollover);
        }
        .navigation__contact > ul > li > div li {
            margin: 10px 0;
            padding-right: 20px;
            }
        .navigation__contact > ul > li > div a {
            font-size: 15px;
            padding: 0;
            text-transform: none;
            letter-spacing: 0px;
            text-decoration: underline;
            &:hover {
                color: var(--brand-pink-rollover);
                }
            }
        .navigation__contact > ul > li > div > ul > li:last-child a {
            color: #B50056;
            text-decoration: none;
            font-size: 13px;
            letter-spacing: 0.1em;
            }
        .navigation__contact > ul > li > div > ul > li:last-child a:hover {
            color: var(--brand-pink-rollover);
        }
        .navigation__container > div > ul > li > a:after {
            display: none;
            }
        .navigation__content > ul > li > ul a {
            padding: 10px 0;
            font-size: 0.9rem;
            }
        .navigation__content > ul > li > ul a:hover {
            color: #B50056;
            }

    .navigation__crisis {
        position: absolute;
        left: -85px;
        bottom: -218px;
        margin-bottom: 0;
        border-radius: 50%;
        width: 500px;
        height: 500px;
        padding-left: 140px;
        padding-top: 70px;
        box-shadow: none;
        }
        
        .navigation__contact {
            display: flex;
        }

        .navigation__contact-form .title,
        .navigation__crisis .title {
            margin-bottom: 0.55em;
            font-size: 1.3rem;
            margin-top: 30px;
            }
        .navigation__contact-form .submitted-message {
            text-align: center;
            padding: 16vh 50px;
            border: 1px solid #B50056;
            text-transform: uppercase;
            margin-bottom: 70px;
            width: 90%;
            }
        .navigation__crisis a,
        .navigation__crisis p {
            margin-bottom: 0.5em;
            font-size: 1rem;
            }
        .navigation__crisis a {
            display: block;
            margin-top: -4px;
            }
        .navigation__crisis a:hover {
            text-decoration: underline;
        }

    .navigation__contact-toggle {
        position: absolute;
        left: calc((50% - 25px) - 12.5%) !important;
        top: auto;
        bottom: 55px;
        width: 190px;
        align-items: center;
        }

        .navigation__contact-toggle a {
            font-size: 1.2rem;
            color: #B50056;
            align-items: flex-end;
            padding: 0;
            }
        .navigation__contact-toggle a:hover {
            color: var(--brand-pink-rollover);
            }
    .navigation__contact-toggle:last-child {
        left: calc((100% - 25px) - 12.5%) !important;
        }

    .navigation__contact-toggle-holder .navigation__contact-toggle:first-child a:after,
    .navigation__contact-toggle-holder .navigation__contact-toggle:last-child a:before {
        position: absolute;
        bottom: 5px;
        right: 10px;
        width: 17px;
        height: 17px;
        top: auto;
        transition: all 150ms ease-out;
        }
    .navigation__contact-toggle-holder .navigation__contact-toggle:last-child a:before {
        right: auto;
        left: -35px;
        }
    .navigation__contact-toggle-holder .navigation__contact-toggle a:hover:before {
        left: -45px;
        }
    .navigation__contact-toggle-holder .navigation__contact-toggle a:hover:after {
        right: 0px;
        }
        
        .navigation__container .navigation__contact > ul {
            display: block;
            width: 50%;
            }
        .navigation__contact> ul > li {
            width: 50%;
            float: left;
            margin-bottom: 33px;
            }
            .navigation__contact> ul > li:nth-child(2n+1) {
                clear: both;
            } 

    /*.navigation__contact__map {
        width: calc(((100% - 110px) * 0.5));
        height: 350px;
        position: absolute;
        left: 55px;
        bottom: 55px;
        }
        .navigation__contact__map > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            }
        .navigation__contact__map img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            }*/
        .navigation__contact-form {
            width: calc(((100% - 110px) * 0.5));
            position: absolute;
            right: 55px;
            top: 30px;
            margin-bottom: 0;
            padding: 0 0 0 55px;
            background: transparent;
            box-shadow: none;
            }
            .navigation__contact-form .title {
                color: #B50056;
                }
            .navigation__contact-form form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                }

            .navigation__contact-form input,
            .navigation__contact-form textarea,
            .navigation__contact-form label {
                font-size: 15px;
            }
            .navigation__contact-form input[type="text"],
            .navigation__contact-form input[type="email"],
            .navigation__contact-form input[type="tel"],
            .navigation__contact-form input[type="submit"] {
                border: 1px solid #B50056;
                padding: 7px 10px;
                }
            .navigation__contact-form input[type="submit"] {
                padding:  10px 15px;
                }
            .navigation__contact-form button,
            .navigation__contact-form textarea {
                border: 1px solid #B50056;
                }
            .navigation__contact-form textarea {
                margin-top: 0px;
                width: 96% !important;
                }
            .navigation__contact-form fieldset {
                width: 100%;
                }
            .navigation__contact-form p {
                display: none;
                }
            .navigation__contact-form .hs-form-field {
                width: 46% !important;
                }
            .navigation__contact-form .hs-form-field:last-child {
                margin-left: 4%;
                margin-right: 0;
                }
            .navigation__contact-form .hs-fieldtype-textarea {
                margin-left: 0 !important;
                width: 100% !important;
                }
            .navigation__contact-form .hs-error-msg {
                font-size: 0.6rem;
                }

  

}

@media (width >= 1200px) and (height > 660px) {
    .navigation__container > div > ul > li > a {
        font-size: 1.3rem;
        }
    .navigation__contact-toggle a {
        font-size: 1.5rem;
        }
    .navigation__content>ul>li>ul a {
        font-size: 1rem;
    }
    .navigation__contact-toggle-holder .navigation__contact-toggle:first-child a:after, .navigation__contact-toggle-holder .navigation__contact-toggle:last-child a:before {
        bottom: 10px;
    }

    
}

@media (width >= 1400px) and (height > 660px) {
    .navigation {
        left: calc( (100vw - 1400px) / 2 );

        }
    .navigation-active .navigation {
        transform: translateY(-50%) translateX(  calc( ((100vw - 1400px) / 2 ) * -1 ) );
        }
    .navigation__toggle {
        align-items: center;
        padding-right: 0;
        }
    .navigation-active .navigation .navigation__toggle {
        padding-right: 0;
    }
    .navigation__toggle > span:nth-child(2) {
        padding-right: 0;

        /*padding-right: 5px;*/
        }
/*     .navigation-active .navigation {
        transform: translateY(-50%) translateX(0);
        } */
}
