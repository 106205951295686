/** ------------------------------
    Glide
    ------------------------------ */
.glide {
    position: relative;
    }
    .glide__slides {
        margin-bottom: 0;
        }
    .glide__bullets {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        }
        .glide__bullet {
            @mixin button-reset;
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
            margin: 0 6px;
            cursor: pointer;
                &:hover {
                    background-color: var(--brand-pink);
                }
            }
        .glide__bullet--active {
            background-color: var(--brand-pink);
            }

.glide--swipeable {
    cursor: grab;
    }
