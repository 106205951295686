/** ------------------------------
    Spinner
    ------------------------------ */
.spinner {
    --size: 50px;

    width: var(--size);
    height: var(--size);
    border: 3px solid rgba(231, 29, 115, .15);
    border-top-color: var(--brand-pink);
    border-radius: 100%;
    animation: spin .7s infinite linear;
    }

.infinite-scroll-request {
    & .spinner {
        position: absolute;
        left: 50%;
        margin-left: calc(var(--size) / -2);
    }
}

@keyframes spin {

    0% {
        transform: rotate(0deg);
        }
    100% {
        transform: rotate(360deg);
        }

}
