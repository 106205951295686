/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }

body {
    background-color: #101136;
    background-size: 100% auto;
    background-position: top right;
    background-repeat: no-repeat;
    overflow-x: hidden;
    }

.navigation-close {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    }

@media only screen and (width >= 1200px) {

    .navigation-active .navigation-close {
        display: block;
        }

    .content-container {
        overflow: hidden;
        }

    .js .page-content {
        transition: all .5s ease-out;

        &:matches(.slide-up) {
            opacity: 0;
            transform: translateY(80px);
            }
        }

}



@media only screen and (width < 1200px) {

    html {
        height: 100%;
        overflow: hidden;
        }
    body {
        height: 100%;
        overflow: hidden;
        }
    body.navigation-active {
        overflow: hidden;
        }
    .scrollable-container {
        height: 100%;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        }
    body.navigation-active .scrollable-container {
        overflow: hidden;
        -webkit-overflow-scrolling: auto;
        }
}

.content-container {
    overflow-x: hidden;
    }



/** ------------------------------
    Main Content
    ------------------------------ */

.main {
    min-height: 1500px;
    }
    .content-full-width {
        position: relative;
        margin-left: calc(100vw - 50%);
        left: 50%;
        width: 100vw;
        margin-left: -50vw;
        }



/** ------------------------------
    Containers
    ------------------------------ */
.container {
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 20px;
    }

@media (width >= 768px) {
    .container {
        margin: 0 60px;
    }
}
@media (width >= 1200px) {
    .container {
        margin: 0 auto;
        padding: 0 20px;
    }
}   

.container--flush {
    padding: 0;
    margin: 0;
    }

.container--fluid {
    max-width: none;
    }

.content-slim {
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    }
.content-header .content-slim {
    max-width: 760px;
    padding-right: 30px;
    }

.content-header .content-slim,
.content-block--title .content-slim {
    margin-left: 0;
    }


/** ------------------------------
    Sections
    ------------------------------ */

.section {
    padding: 70px 0;
    }

.content-block {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
        @media (width >= 768px) {
            margin-top: 3rem;
            margin-bottom: 3rem;
            }
        @media (width >= 1024px) {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
    }
.content-block > .title {
    margin-bottom: 2rem;
        @media (width >= 768px) {
            margin-bottom: 2rem;
        }
        @media (width >= 1024px) {
            margin-bottom: 3rem;
        }
    }

.action-sheet {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
    @media (width >= 768px) {
        margin-top: 6.5rem;
        margin-bottom: 6.5rem;
    }
    @media (width >= 1024px) {
        margin-top: 8.5rem;
        margin-bottom: 8.5rem;
    }
}

.awards-block {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
    }
.social-connect,
.cta-block {
    margin-top: 7rem;
    margin-bottom: 7rem;
    @media (width >= 1024px) {
        margin-top: 9rem;
        margin-bottom: 9rem;
        }
    }
.card-grid-block {
    margin-top: 5rem;
    margin-bottom: 5rem;
    }
    .card-grid-block .title {
        margin-bottom: 1rem;
    }
    .card-grid-block > a + .card-grid {
        margin-top: 4rem;
        }
    .content-block--link-portal {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 80px;
        padding-bottom: 80px
        }
    .content-block--title {
        margin-top: 0;
        margin-bottom: 0;
        }
    .content-block--services-grid {
        margin-top: 0;

        & .card {
            margin: 0;
            }
        }


@media only screen and (width >= 768px) {

    .section {
        padding: 80px 0;
        }
    .awards-block {
        margin-top: 9rem;
        margin-bottom: 9rem;
        }
    .content-block--link-portal {
        padding-top: 140px;
        padding-bottom: 140px
        }
    .home .content-block--link-portal {
        padding-top: 240px;
        padding-bottom: 240px;
        }
    .section--awards {
        padding: 200px 0;
        }
}
