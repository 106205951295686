.social-feed {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    }
    .social-feed__post {
        width: 50%;
        position: relative;
        background: linear-gradient(0deg,#111236 0,rgba(85,80,115,0) 90%);
        }
        .social-feed__post__content {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg,#111236 0,rgba(85,80,115,0) 90%);
            }
        .social-feed__post__comment {
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0;
            padding: 0 25px;
            color: #fff;
            font-size: 0.5rem;
            }

.social-connect ul {
    list-style: none;
    text-align: center;
    margin: 2.5rem 0 0 0;
    padding: 0;
    }
    .social-connect ul li {
        display: inline-block;
        margin: 0 0.5rem;
        }
        .social-connect ul li a {
            display: block;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #B50056;
            border-radius: 50%;
            }
            .social-connect ul li a svg {
                width: 25px;
                height: auto;
                }

@media (width >= 768px) {

}

@media (width >= 1200px) {
    .social-feed__post {
        width: 25%;
        }
    .social-feed__post:hover {
        transform: scale(1.25);
        transform-origin: 50% 50%;
        z-index: 2;
        }
    .social-feed__post__content {
        display: block;
        opacity: 0;
        }
    .social-feed__post:hover .social-feed__post__content {
        opacity: 1;
        }
        .social-feed__link-out {
            display: flex;
            align-items: center;
            position: absolute;
            top: 10px;
            right: 10px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-size: 0.4rem;
            }
            .social-feed__link-out svg {
                margin-left: 5px;
                }

      .social-connect ul li {
            margin: 0 2rem;
            }
            .social-connect ul li a {
                width: 90px;
                height: 90px;
                }
                .social-connect ul li a svg {
                    width: 44px;
                    }
}
