/** ------------------------------
    Mixins
    ------------------------------ */

/*
    Only place global mixins here.
    Component-specific mixins such as `button` should be in
    the relevant component file.
*/

@define-mixin font-scale $value {
    font-size: calc($value / var(--base-font-size) * 1rem);
}

@define-mixin base-margin $scale: 1 {
    margin-top: 0;
    margin-bottom: calc(var(--base-rhythm) * $scale);
}

/* Grid mixins are used for layouts that a similar to grids, but not a lazy .grid component */
@define-mixin grid-container {
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
}

@define-mixin grid-item {
    padding-left: calc(var(--grid-gutter) / 2);
    padding-right: calc(var(--grid-gutter) / 2);
}

@define-mixin flex-center {
    display: flex;
    align-content: center;
    justify-content: center;
}

@define-mixin list-reset {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

@define-mixin button-reset {
    appearance: none;
    outline: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    line-height: 1;
}

@define-mixin box-shadow {
    box-shadow: -10px 10px 30px 0px rgba(0, 0, 0, .46);
}

@define-mixin text-uppercase {
    text-transform: uppercase;
    letter-spacing: .015em;
}

@define-mixin content-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000;
}
