.content-header--profile {
    margin-bottom: 5rem;
    }

.profile__location {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #B50056;
    }
.content-header--profile .title--sub {
    margin-bottom: 1.25rem;
    }

.content-header--profile .content-slim p {
    margin-bottom: 5px;
    }

.profile__social {
    list-style: none;
    margin: 25px 0;
    padding: 0;
    }
    .profile__social li {
        display: inline-block;
        }
    .profile__social li + li {
        margin-left: 12px;
        }
        .profile__social li a {
            width: 36px;
            height: 36px;
            border: 1px solid #B50056;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            }
            .profile__social li a svg {
                width: 16px;
                height: 16px;
                }
.content-header--profile {
    @media (width >= 500px) {
        display: flex;
    }
}
.content-header--profile .content-profile-img {
    /*margin-top: 40px;*/
    & img { 
        width: 100%;
        }
    }

.profile__work,
.profile__education {
    margin-top: 4rem;
    }

@media (width >= 768px) {
    .content-header--profile .content-profile-img {
        width: 50%;
        margin-top: 15px;
        }
    .content-header--profile .content-profile-img img {
        width: 100%;
        }
    .content-header--profile .content-slim {
        padding-right: 6%;
        width: 50%;
        }
}

