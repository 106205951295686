/** ------------------------------
    Hero Carousel About
    ------------------------------ */

.hero-carousel__about {
    height: 100%;
    display: flex;
    flex-direction: column;
    white-space: normal;
    align-items: center;
    justify-content: center;
    }

@media (width < 768px) {
    .hero-carousel__about {
        padding-top: 100px;
        }
}

@media (height < 600px) {
    .hero-carousel__about {
        height: 600px;
        }
} 

.hero-carousel__about {

    }
    .hero-carousel__about > div {
        margin-top: 135px;
        max-width: 960px;
        padding: 0 40px;
        margin: 0 auto;
        text-align: center;
        }

.hero-carousel__about h1 {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    margin-top: 0;
    }
.hero-carousel__about p {
    font-size: 0.72rem;
    max-width: 680px;
    margin: 30px auto 0;
    }

.hero-carousel__about ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
    padding: 0;
    }
    .hero-carousel__about ul li {
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 0.72rem;
        text-align: right;
        width: 50%;
        padding: 5px 10px;
        }
        .hero-carousel__about ul li a {
            color: #fff;
            text-decoration: none;
            }
             .hero-carousel__about ul li a span {
                display: none;
                }
    .hero-carousel__about ul li:nth-child(2),
    .hero-carousel__about ul li:nth-child(4) {
        text-align: left;
        }




@media (width >= 768px) {
    .hero-carousel__about ul {
        margin: 60px 0 0 0;
        }
    .hero-carousel__about {
        height: 100vh !important;
        min-height: 625px;
        }
        .hero-carousel__about h1 {
            color: #fff;
            font-size: 2.15rem;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 0;
            margin-bottom: 1.4rem;
            }
        .hero-carousel__about p {
            font-size: 1rem;
            }
        .hero-carousel__about ul {
            justify-content: center;
            }
        .hero-carousel__about ul li {
            font-size: 0.85rem;
            width: auto;
            padding: 5px 20px;
            letter-spacing: 1px;
            }
            .hero-carousel__about ul li a {
                transition: color 150ms ease-out;
                }
            .hero-carousel__about ul li a:hover {
                color: #B50056;
                }
                .hero-carousel__about ul li a span {
                    display: inline-block;
                    opacity: 0;
                    margin-left: 5px;
                    position: relative;
                    top: -1px;
                    left: 0;
                    transition: all 150ms ease-out;
                    }
                .hero-carousel__about ul li a:hover span {
                    opacity: 1;
                    left: 10px;
                    }

    .hero-carousel__about {
        padding-bottom: 55px;
        }
        .home-hero__carousel__item--center {
            /* align-items: flex-end; */
            }
        .hero-carousel__about > div {
            margin-top: 170px;
            }

}
