/** ------------------------------
    Link Portal
    ------------------------------ */
.link-portal {
    position: relative;
    }
    .link-portal__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--brand-purple);
        z-index: -1;

        & > span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg,rgba(85,80,115,0) 0,#111236 100%);
            opacity: 0.7;
            }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .3;
            }
        }
