.location-map {
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: 6rem;
    }
    .location-map > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #221c46;
        }

.locations__item {
    text-decoration: none;
    }
    .locations__item p {
        color: #fff;
        }

.locations .card-grid--lg {
    justify-content: space-between;
    }

.locations__card__content p {
    font-size: 0.85rem;
    }
    .locations .card-grid--lg .card {
        padding: 0;
        }
        .locations__card__holder {
            padding-bottom: 100%;
            position: relative;
            background: rgba(17,18,54,0.7);
            transition: background 250ms ease-out;
            }
        .locations__card__holder:hover {
            /* background: rgba(17,18,54, 0); */
            background: linear-gradient(to top, #111236 0%,rgba(85,80,115,0) 90%);
            }
        .locations__card__content {
            position: absolute;
            bottom: 30px;
            width: 100%;
            padding: 0 20px;
            text-align: center;
            color: white;
            }
            
@media (width >= 768px) {
    .locations .card-grid--lg {
        margin: 0;
        }
    .locations .card-grid--lg .card {
        width: calc(50% - 15px);
        margin-bottom: 30px;
        margin-top: 0;
        }
}
@media (width >= 1024px) {
    .locations .card-grid--lg {
        /*justify-content: center;*/
        display: block;
        text-align: center;
        margin-left: -15px;
        margin-right: -15px;
    }
    .locations .card-grid--lg .card {
        width: calc(33% - 30px);
        margin-bottom: 30px;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        }
    .locations__card__content p {
        font-size: 0.8rem;
    }
}
@media (width >= 1200px) {
    .locations__card__content p {
        font-size: 13px;
    }
}
