/** ------------------------------
    Archive pages
    ------------------------------ */
.infinite-scroll-target {
    position: relative;

    & .spinner {
        position: absolute;
        left: 50%;
        transform: translate3d(30px, 50%, 0);
        }

    }
