.content-filter {
    position: relative;
    z-index: 5;
    }

.content-filter__option-title {
    text-transform: uppercase;
    color: #6F437F;
    font-size: 0.55rem;
    }
    .content-filter__option + .content-filter__option {
        margin-top: 20px;
        }
    .content-filter__option ul {
        list-style: none;
        margin: 0 -10px;
        padding: 0;
        }
        .content-filter__option li {
            padding: 2px 10px;
            color: #fff;
            cursor: pointer;
            border: 1px solid transparent;

            &:hover, &:matches(.is-active) {
                border-color: currentcolor;
                }

            &:matches(.is-disabled) {
                opacity: .5;
                pointer-events: none;
                }
            }
            .content-filter__option li a {
                color: currentcolor;
                font-size: 1.11rem;
                text-decoration: none;
                }

.content-filter__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    & > button {
        @mixin button-reset;
        color: #fff;
        text-decoration: none;
        padding: 5px 10px;
        border: 1px solid #B50056;
        text-transform: uppercase;
        font-size: 0.833rem;
        }
    }

@media (width <= 768px) {

    .content-filter__option li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        }

}

@media (width >= 768px) {

    .content-filters {
        padding-bottom: 40px;
        border-bottom: 1px solid #B50056;
        margin-bottom: 0;

        }
    .content-filter__controls {
        margin-bottom: 30px;

        & > button {
            padding: 5px 15px;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all .25s ease-out;
            &:hover {
                background: #B50056;
                color: #fff;
                top: -2px;
                box-shadow: -5px 5px 15px 0 rgba(0,0,0,.5);
                }
            }
        }
    .content-filter__options {
        display: flex;
        justify-content: space-between;
        margin: 0 -25px;
        margin-bottom: 20px;
        }
        .content-filter__option {
            flex: 1;
            padding: 0 25px;
            }
        .content-filter__option + .content-filter__option {
            margin-top: 0;
            }
            .content-filter__option-title {
                color: #B50056;
                font-size: 1rem;
                margin-bottom: 20px;
                letter-spacing: 1px;
                }
            .content-filter__options li {
                display: block;
                }
                .content-filter__options li + li {
                    margin-top: 10px;
                }
                .content-filter__options li a {
                    display: block;
                    font-size: 1rem;
                    }
}
