.card-holder--filled {
    background: #1e1946;
    padding: 20px;
    }

.card-holder--filled .card-grid {
    /*margin-top: 30px;*/
    }
.card {
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    }
.card:hover .card__content__copy {
    color: #fff;
}
.card--job .card__content--post {
    padding: 0px;
    background: rgba(34,28,70,0.7);
    transition: background 250ms ease-out;
    }
    .card__content__copy > p.card--job__description {
        font-size: 1rem;
        margin-bottom: 0;
        }
    .card--job  .card__content__copy>p:last-of-type {
        margin-bottom: 0 !important;
        }

    .card__content--post__img {
        width: 100%;
        display: block;
        }
        .card__content__copy {
            padding: 20px;
            color: white;
            }
        .card-holder--filled .card__content__copy {
            padding: 0;
            }
        .card__content--post__info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            }
            .card__content__type {
                color: #B50056;
                font-size: 0.55rem;
                }
            .card--job:hover .card__content--post__info + p {
                color: #B50056;
                text-decoration: underline;
                }
            .card--job .card__content__type {
                color: #fff;
                }
            .card__content__client {
                font-size: 0.55rem;
                margin-left: 15px;
                }
            .card__content__link {
                margin-left: auto;
                }
            .card__content__details {
                display: block;
                margin-top: 20px;
                font-size: 0.55rem;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                }
                .card__content__details * + * {
                    &::before {
                        content: "&bull;";
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                        }
                    }
            .card__content__copy > p:last-of-type {
                margin-bottom: 0;
                }

.card-grid__more {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 0.6rem;
    text-decoration: none;
    line-height: 1.5;
    letter-spacing: 0.1em;
    width: 80px;
    margin: 30px auto 0;
    transition: all 250ms ease-out;
        .fill {
            transition: fill 250ms ease-out;
            }
        &:hover .fill {
            fill: var(--brand-pink);
            }
    }

.search-results .card + .card {

    }
    .search-results .card__content--post__info {

        }

@media (width > 1320px) {
    .card-grid {
        position: relative;
        }
        .card-grid__more {
            position: absolute;
            top: 100px;
            right: -80px;
            width: 55px;
            }
    }
    .card-grid__more__icon {
        width: 50px;
        height: 50px;
        border: 2px solid #B50056;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 12px;
        }

@media (width < 768px) {
    .card-grid + .card-grid,
    .card + .card {
        margin-top: 30px;
        }
}

@media (width >= 768px) {
    .card-holder--filled {
        padding: 35px 35px 0 35px;
        }
    .card-holder--filled .title {
        margin-bottom: 0rem;
        }
    .card-grid {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        /*margin-top: -65px;*/
        }
        .card-grid + .card-grid {
            margin-top: 3.5rem;
            }
    .jobs.card-grid--lg {
        margin-top: 0;
        padding-bottom: 60px;
        }
    .search-results .card-grid {
        display: block;
        margin-top: 0;
        }
    .card-grid--team {
        margin-top: 0;
        }
    .card-holder--filled .card {
        margin-top: 0;
        }
    .card-grid--services {
        margin-top: 0;
        }
    .card {
        padding: 15px;
        width: 33.33%;
        /*margin-left: -15px;*/
        /*margin-right: -15px;*/
        margin: 0 0 10px 0;
        display: flex;
        flex-direction: column;
        transition: opacity 500ms ease-out, background 250ms ease-out, box-shadow 250ms ease-out;
        }
    .locations .card,
    .card--services.card {
        margin-left: 0;
        margin-right: 0;
        }
    .jobs.card-grid--lg .card {
        margin-top: 0;
        margin-bottom: 0;
        }
        .search-results .card {
            width: 100%;
            }
    .card--services {
        margin-top: 0;
        }
    .card-grid--lg .card {
        width: 50%;
        }
    .card-grid--xl .card {
        width: 100%;
        }
    .card:hover {
        background: #221C46;
        box-shadow: -10px 10px 30px 0px rgba(0,0,0,0.5);
        }
    .card--job .card__content--post {
        padding: 30px;
        }
    .card--job:hover {
        background: transparent;
        box-shadow: none;
        }
    .card--job:hover .card__content--post {
        background: rgba(34,28,70,1);
        }
    .card-holder--filled .card:hover {
        background: transparent;
        box-shadow: none;
        }
        .card__content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            }
        .search-results .card__content {
            flex-direction: row;
            }
            .search-results .card__content--post .responsive-media {
                max-width: 350px;
                width: auto;
                height: auto;
                padding-bottom: 0;
                }
            .search-results .card__content--post__img {
                max-width: 350px;
                width: auto;
                height: auto;
                position: static;
                }
        .card__content__copy {
            padding: 20px 0 0 0;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            }
            .search-results .card__content__copy {
                padding-top: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 10px 10px 5px 15px;
                }
        .card--job .card__content__copy {
            padding-top: 0;
            }
            .card__content__type {
                font-size: 0.65rem;
                }
            .card__content__client {
                font-size: 0.65rem;
                }
            .card__content__details {
                font-size: 0.65rem;
                margin-top: auto;
                }
                .search-results .card__content__details {
                    margin-top: 0;
                    }
            .card__content__copy > p {
                font-size: 1.25rem;
                margin-bottom: 20px !important;
                }
            .card-grid--xl .card__content__copy > p {
                font-size: 2.15rem;
                margin-bottom: 30px !important;
                }
            .search-results .card__content__copy > p {
                margin-bottom: 10px !important;
                }
}

@media (width >= 1024px) {
    .card {
        margin: 30px 0 10px 0;
        padding: 30px;
        }
    .card-grid {
        margin-left: -30px;
        margin-right: -30px;
    }
    .card-grid__more {
        margin: 80px auto 0;
    }
}
@media (width >= 1200px) {
    .card {
        margin: 65px 0 10px 0;
        }
}

