.img-carousel {
    position: relative;

    & img {
        width: 100%;
        }
    }
    .img-carousel__controls > div {
        position: absolute;
        top: 50px;
        bottom: 50px;
        left: 0;
        width: 60px;
        background: rgba(34,28,70,0.5);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 250ms ease-out;
        }
    .img-carousel__controls > div:hover {
        background: rgba(34,28,70,1);
        }
    div.img-carousel__controls__next {
        left: auto;
        right: 0;
        }
    .img-carousel__controls__prev svg {
        transform: rotate(180deg);
        }
    .img-carousel__caption {
        margin-top: 5px;
        font-size: .75em;
        }
